import React from 'react';
import Layout from 'templates/Layout/Layout';
import Container from 'components/atoms/Container/Container';
import Footer from 'components/organisms/Footer/Footer';
import CustomHeader from 'components/organisms/CustomHeader/CustomHeader';
// import Services from 'components/organisms/Services/Services';
import ShortServices from 'components/organisms/ShortServices/ShortServices';

const Page = () => (
  <Layout
    metaTitle="Poznaj nasz kompleksowy serwis samochodowy - Auto Serwis Kubeczek"
    metaDescription="Wymiana opon, wymiana olejów i filtrów, diagnostyka komputerowa pojazdu, naprawa układu klimatyzacji oraz wiele więcej sprawdź nasze usługi."
  >
    <CustomHeader title={'Poznaj nasze <b>usługi</b>'} />
    <Container>
      {/* <Services /> */}
      <ShortServices />
    </Container>
    <Footer />
  </Layout>
);

export default Page;
